<template>
    <ts-loading-banner :loading="waiting">
        <div class="tw-space-y-2">
            <div class="tw-flex tw-space-x-3">
                <div
                    class="tw-border tw-h-32 tw-w-48 tw-rounded-lg tw-p-1 tw-bg-white tw-overflow-hidden tw-relative"
                    @mouseover="() => (showBrowseFileBotton = true)"
                    @mouseleave="() => (showBrowseFileBotton = false)"
                >
                    <img
                        :src="logo_file_src"
                        alt=""
                        class="tw-object-contain tw-w-full tw-h-full"
                    />
                    <div
                        class="tw-w-full tw-h-full tw-opacity-30 tw-bg-gray-600 tw-absolute tw-top-0 tw-left-0 tw-transform tw-duration-500"
                        v-show="showBrowseFileBotton"
                    ></div>
                    <div
                        class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center"
                        v-show="showBrowseFileBotton"
                    >
                        <label
                            for="logo-image"
                            class="tw-text-white tw-p-2 tw-bg-blue-500 tw-opacity-100 tw-cursor-pointer tw-rounded-lg"
                            >{{ $t("browseFile") }}</label
                        >
                        <input
                            type="file"
                            id="logo-image"
                            ref="logoimage"
                            style="display:none"
                            @change="onFileChange"
                        />
                    </div>
                </div>
                <div class="tw-w-full">
                    <div class="row tw-space-y-2">
                        <div class="col-md-12 tw-space-y-1">
                            <label class="required">{{
                                $t("branchProfile.companyName")
                            }}</label>
                            <select
                                v-model="model.company_id"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('company_id')
                                }"
                            >
                                <option value="">{{
                                    $t("branchProfile.companyName")
                                }}</option>
                                <option
                                    v-for="(com, index) in companies"
                                    :key="index"
                                    :value="com.company_id"
                                    >{{ com.company_name_en }}</option
                                >
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('company_id')"
                            >
                                {{ errors.first("company_id") }}
                            </div>
                        </div>
                        <div class="col-md-12 tw-space-y-1">
                            <label>{{ $t("branchProfile.nameKH") }}</label>
                            <input
                                v-model="model.branch_name_kh"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('branch_name_kh')
                                }"
                                :placeholder="$t('companyProfile.nameKH')"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('branch_name_kh')"
                            >
                                {{ errors.first("branch_name_kh") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("branchProfile.nameEN")
                    }}</label>
                    <input
                        v-model="model.branch_name_en"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('branch_name_en')
                        }"
                        :placeholder="$t('companyProfile.nameEN')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('branch_name_en')"
                    >
                        {{ errors.first("branch_name_en") }}
                    </div>
                </div>
                <div class="col-6 tw-space-y-1">
                    <label class="required">{{
                        $t("branchProfile.code")
                    }}</label>
                    <input
                        v-model="model.branch_code"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('branch_code') }"
                        :placeholder="$t('branchProfile.code')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('branch_code')"
                    >
                        {{ errors.first("branch_code") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <label class="required">{{
                        $t("branchProfile.phone")
                    }}</label>
                    <input
                        v-model="model.branch_phone"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('branch_phone') }"
                        :placeholder="$t('companyProfile.phone')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('branch_phone')"
                    >
                        {{ errors.first("branch_phone") }}
                    </div>
                </div>
                <div class="col-6 tw-space-y-1">
                    <label>{{ $t("branchProfile.email") }}</label>
                    <input
                        v-model="model.branch_email"
                        type="email"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('branch_email') }"
                        :placeholder="$t('companyProfile.email')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('branch_email')"
                    >
                        {{ errors.first("branch_email") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label>{{ $t("branchProfile.address") }}</label>
                    <textarea
                        v-model="model.branch_address"
                        rows="3"
                        class="form-control"
                        :placeholder="$t('companyProfile.address')"
                        :class="{ 'is-invalid': errors.has('branch_address') }"
                    ></textarea>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('branch_address')"
                    >
                        {{ errors.first("branch_address") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from "lodash";
import { mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "branchForm",
    data() {
        return {
            waiting: false,
            errors: new Errors(),
            showBrowseFileBotton: false,
            logo_file: "",
            logo_file_src: require("../../../assets/default-image.jpg"),
            companies: [],
            model: {
                branch_id: null,
                company_id: "",
                branch_code: null,
                branch_name_kh: null,
                branch_name_en: null,
                branch_address: null,
                branch_phone: null,
                branch_email: null,
                branch_logo: null
            }
        };
    },
    computed: {
        ...mapState("corporate/branchProfile", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    async created() {
        await this.fetchFormViewModel();
        this.setEditData();
    },
    methods: {
        onFileChange() {
            const file = this.$refs.logoimage.files[0];
            let reader = new FileReader();
            reader.addEventListener(
                "load",
                function() {
                    this.logo_file_src = reader.result;
                }.bind(this),
                false
            );
            if (file) {
                if (/\.(jpe?g|png)$/i.test(file.name)) {
                    reader.readAsDataURL(file);
                    this.logo_file = file;
                } else {
                    this.logo_file_src = require("../../../assets/default-image.jpg");
                    this.$toasted.error("invalid file type!");
                    this.$refs.logo_file.value = "";
                }
            }
        },
        async onSave() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/branchProfile/store", this.model)
                .then(async response => {
                    await this.uploadLogo(response.branch_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/branchProfile/update", {
                    id: this.model.branch_id,
                    data: this.model
                })
                .then(async response => {
                    await this.uploadLogo(this.model.branch_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        fetchFormViewModel() {
            this.waiting = true;
            this.$store
                .dispatch("corporate/branchProfile/getFormViewData")
                .then(response => {
                    this.companies = response.data.company;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.branch_id = this.edit_data.branch_id;
                this.model.company_id = this.edit_data.company_id;
                this.model.branch_code = this.edit_data.branch_code;
                this.model.branch_name_kh = this.edit_data.branch_name_kh;
                this.model.branch_name_en = this.edit_data.branch_name_en;
                this.model.branch_address = this.edit_data.branch_address;
                this.model.branch_phone = this.edit_data.branch_phone;
                this.model.branch_email = this.edit_data.branch_email;
                this.model.branch_logo = this.edit_data.branch_logo;
                if (this.edit_data.branch_logo) {
                    this.logo_file_src = this.edit_data.branch_logo;
                }
            }
        },
        async uploadLogo(branchId) {
            if ((this.logo_file instanceof File) | Blob) {
                let formData = new FormData();
                formData.append("file", this.logo_file);
                formData.append("branch_id", branchId);
                let response = await this.$store.dispatch(
                    "corporate/branchProfile/upload",
                    formData
                );
                this.model.branch_logo = response;
            }
            return "";
        }
    }
};
</script>
