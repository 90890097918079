var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('branchProfile.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('branchProfile.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")])],1),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.branch_code))]),_c('td',[_vm._v(_vm._s(record.company_name_en))]),_c('td',[_vm._v(_vm._s(record.branch_name_kh))]),_c('td',[_vm._v(_vm._s(record.branch_name_en))]),_c('td',[_vm._v(_vm._s(record.branch_phone))]),_c('td',[_vm._v(_vm._s(record.branch_address))]),_c('td',{staticClass:"text-center tw-space-x-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(record)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('a-popconfirm',{attrs:{"placement":"leftTop","title":_vm.$t('are_you_sure_to_delete'),"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"confirm":function($event){return _vm.deleteRecord(record)}}},[_c('a',{attrs:{"href":"#","disabled":record._deleting}},[(record._deleting)?_c('i',{staticClass:"fas fa-circle-notch spin"}):_c('i',{staticClass:"fa fa-trash text-red"})])])],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),(_vm.showForm)?_c('a-modal',{attrs:{"title":_vm.$t('branchProfile.pageTitle'),"centered":"","maskClosable":false,"zIndex":1020,"footer":null},on:{"cancel":_vm.clearEdit},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{on:{"cancel":_vm.clearEdit}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }